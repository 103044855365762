<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <!-- <h1>Cam kết bảo mật</h1> -->
        <!-- <div class="title-line"></div> -->
        <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import importedImg1 from "@/page/ksppsbungasinarmentari/components/img/4_1.png";

export default {
  data() {
    return {
      altText: 'protocolTitle',
      importedImg1: importedImg1,
      text: `Kami menghormati hak privasi semua orang yang mengakses situs web ini dan berkomitmen untuk melindungi informasi pribadi mereka. Kebijakan privasi ini berlaku untuk semua pengunjung situs web kami dan untuk semua anak perusahaan kami.

Dengan menggunakan situs web ini, Anda mengakui dan setuju dengan Kebijakan privasi ini. Namun, jika Anda keberatan dengan penggunaan, pengolahan, dan pengalihan informasi Anda dalam bentuk apa pun, silakan jangan berbagi informasi Anda di situs web ini.

Jenis Informasi Pribadi yang Dikumpulkan
Kami dapat mengumpulkan informasi pribadi seperti yang dijelaskan di bawah ini untuk tujuan penyediaan layanan:

Informasi yang mungkin Anda berikan secara langsung, seperti:

Informasi identitas: nama, jenis kelamin, alamat tempat tinggal/korespondensi, nomor telepon, tanggal lahir, status pernikahan, alamat email atau informasi kontak lainnya.
Status PAN, KYC, tanda tangan, dan foto.
Rincian rekening bank atau metode pembayaran lainnya.
Rincian lainnya yang mungkin kami perlukan untuk memberikan layanan.
Informasi yang mungkin kami kumpulkan saat Anda menggunakan layanan kami, seperti:

Informasi transaksi: Kami hanya membaca, mengumpulkan, dan memantau pesan transaksi keuangan untuk menggambarkan transaksi dan jumlah yang sesuai untuk menilai risiko kredit. Tidak ada data SMS lain yang diakses.
Informasi penyimpanan: Kami dapat memfasilitasi pengguna untuk mengunduh dan menampilkan informasi seperti rincian rencana kepercayaan untuk referensi pengguna atau mengunggah dokumen terkait dalam berbagai proses seperti pengelolaan akun pengguna atau penempatan pesanan transaksi.
Informasi media: Kami memfasilitasi pengguna untuk mengumpulkan/mengunggah dokumen terkait yang mungkin perlu diunggah dalam proses pengelolaan akun pengguna atau penempatan pesanan transaksi.
Informasi perangkat: Saat Anda mengakses Layanan kami, kami akan mengumpulkan beberapa informasi tertentu tentang perangkat Anda, termasuk kapasitas penyimpanan, jenis perangkat keras, sistem operasi dan versi, nomor identifikasi perangkat unik, informasi jaringan seluler, dan informasi tentang perangkat Anda serta interaksi dengan Layanan kami.
Ketika Anda memilih kontak sebagai referensi dalam proses peminjaman, kami akan membaca informasi nama dan nomor telepon. Kami tidak mengunggah daftar kontak Anda ke server kami.
Informasi Log yang Disimpan Secara Otomatis
Jika Anda mengakses/memasuki situs web kami hanya untuk menjelajahi, membaca halaman, atau mengunduh informasi, beberapa informasi tertentu tentang kunjungan Anda akan secara otomatis disimpan dalam sistem kami. Informasi ini tidak dapat dan tidak akan mengidentifikasi Anda secara pribadi.

Jenis informasi yang dikumpulkan secara otomatis termasuk tetapi tidak terbatas pada:

Jenis browser yang Anda gunakan (misalnya: Internet Explorer, Firefox, dll.),
Jenis sistem operasi yang Anda gunakan (misalnya: Windows atau Mac OS), dan
Nama domain penyedia layanan internet Anda, tanggal dan waktu Anda mengakses serta halaman di situs web kami.
Terkadang kami menggunakan informasi ini untuk meningkatkan desain dan konten situs web kami, terutama untuk memberikan Anda pengalaman menjelajah yang lebih baik.

Kebijakan ini tidak dimaksudkan dan tidak menciptakan hak kontrak atau hak hukum lainnya bagi pengguna atau pengunjung kami atau mewakili pihak mana pun.

Tujuan Pengumpulan dan Penggunaan Informasi
Di situs web kami, kami hanya mengumpulkan, menyimpan, dan menggunakan informasi Anda ketika kami memiliki alasan untuk percaya bahwa informasi Anda akan membantu mengelola operasi bisnis kami atau menyediakan produk, layanan, dan peluang lain kepada Anda. Informasi ini dikumpulkan untuk tujuan bisnis tertentu, seperti:

Untuk memberikan layanan yang mungkin Anda butuhkan,
Memproses permintaan transaksi keuangan dan non-keuangan Anda,
Melakukan penelitian dan analisis untuk menyediakan atau meningkatkan layanan kami,
Untuk meninjau dan memproses aplikasi yang mungkin Anda kirim untuk menggunakan layanan keuangan apa pun,
Berbagi dengan Anda semua pembaruan/perubahan terhadap Layanan serta syarat dan ketentuannya,
Menerima dan menyelidiki setiap keluhan/sengketa,
Menjawab pertanyaan dan umpan balik yang Anda kirim,
Untuk memverifikasi identitas Anda dan parameter lainnya,
Untuk mematuhi persyaratan hukum/peraturan yang berlaku dan/atau perintah pengadilan/petunjuk regulasi yang kami terima.
Pengungkapan Informasi
Informasi yang Anda berikan dapat diungkapkan kepada:

Penyimpanan Informasi
Kami tidak akan menyimpan atau menyimpan informasi tersebut lebih lama dari yang diperlukan untuk tujuan tersebut, kecuali informasi tersebut dapat digunakan secara hukum atau diminta menurut hukum lainnya yang berlaku pada saat ini.

Dengan menyetujui penggunaan layanan yang kami sediakan, Anda juga setuju dengan pengumpulan dan penggunaan data atau informasi pribadi sensitif Anda. Anda selalu memiliki hak untuk menolak atau mencabut persetujuan Anda untuk berbagi/mendistribusikan data atau informasi pribadi sensitif Anda dengan menghubungi layanan pelanggan kami. Namun, jika Anda menolak atau mencabut data pribadi Anda, Anda tidak akan dapat memanfaatkan layanan kami secara maksimal.

Komunikasi dan Pemberitahuan
Saat Anda menggunakan Situs web atau mengirim email atau data, informasi, atau komunikasi lainnya kepada kami, Anda setuju dan memahami bahwa Anda sedang berkomunikasi dengan kami secara elektronik dan Anda setuju untuk menerima komunikasi berkala dari kami. Kami dapat memberikan pemberitahuan kepada Anda melalui email atau pemberitahuan tertulis atau dengan menampilkan pemberitahuan tersebut secara mencolok di halaman web kami. Anda dapat memilih untuk tidak berpartisipasi dalam beberapa metode pemberitahuan tertentu jika Anda merasa perlu.

Memperbarui atau Meninjau Informasi Anda
Anda dapat meninjau data pribadi atau informasi yang telah Anda berikan dengan permintaan tertulis kepada kami. IIFL akan memastikan bahwa semua informasi pribadi atau data pribadi sensitif atau informasi yang ditemukan tidak akurat atau keliru akan diperbaiki atau diubah jika memungkinkan.

Praktik Keamanan yang Wajar untuk Melindungi Informasi Anda
Kami menggunakan langkah-langkah perlindungan fisik, administratif, dan teknis yang wajar secara komersial untuk melindungi integritas dan keamanan informasi pribadi Anda. Ini termasuk penilaian internal tentang kegiatan pengumpulan, penyimpanan, dan pemrosesan data serta langkah-langkah keamanan kami, seperti langkah-langkah keamanan fisik dan enkripsi yang sesuai, untuk mencegah akses yang tidak sah ke sistem kami di mana data pribadi disimpan.

Semua informasi yang dikumpulkan di situs web yang disebutkan di atas disimpan dengan aman dalam basis data yang kami kendalikan. Basis data disimpan di server yang aman; akses ke dalamnya dilindungi dengan kata sandi dan sangat dibatasi.

Untuk melindungi privasi dan keamanan Anda, kami melakukan langkah-langkah yang wajar (seperti meminta kata sandi unik) untuk memverifikasi identitas Anda sebelum memberikan akses ke akun Anda. Anda bertanggung jawab untuk menjaga kerahasiaan informasi akun dan kata sandi unik Anda serta mengendalikan akses kami ke informasi kontak melalui email Anda setiap saat.

Meskipun kami menggunakan langkah-langkah keamanan untuk membantu melindungi informasi pribadi Anda dari pengungkapan, penyalahgunaan, atau perubahan yang tidak sah (seperti semua jaringan komputer yang terhubung ke Internet), kami tidak dapat menjamin atau menjamin keamanan informasi apa pun yang Anda kirimkan kepada kami. Risiko. Ketika kami menerima informasi yang Anda kirimkan, kami akan menggunakan upaya yang wajar secara komersial untuk memastikan keamanan informasi tersebut.

Tautan ke Situs Web Lain
Harap dicatat bahwa Kebijakan privasi ini tidak berlaku untuk situs web pihak ketiga yang terhubung dengan situs web ini. Kami tidak bertanggung jawab atas konten dan langkah-langkah keamanan situs terkait tersebut. Anda harus membaca pernyataan privasi masing-masing situs web yang terhubung sebelum berbagi informasi apa pun.

Perubahan Kebijakan Privasi Kami
Harap dicatat bahwa kebijakan ini dapat berubah dari waktu ke waktu. Jika kami mengubah kebijakan dan prosedur privasi, kami akan memposting perubahan tersebut di situs web kami untuk memberi tahu Anda. Perubahan pada kebijakan ini berlaku sejak tanggal mereka diposting di halaman ini. Silakan kunjungi situs web kami untuk mengetahui setiap perubahan terhadap kebijakan ini.

Keluhan:
Setiap perbedaan dan keluhan terkait pemrosesan dan penggunaan informasi Anda dapat diajukan kepada staf penanganan keluhan yang ditunjuk. Untuk rincian lebih lanjut, silakan kunjungi Prosedur Penanganan Keluhan.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}

.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.imgItem2 {
  max-width: 100%;
}

.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

h1 {
  margin-top: 50px;
  margin-left: 490px;
  color: #3F4842;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}

.textBox {
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}

.imgBox2 {
  transform: scale(90%);
  /* transform: scale(80%); */
  margin: -190px auto 0px;
  height: 3400px;
  /* box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.25); */
  /* border: 2px solid #333333; */
}

.textBox {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

/* .imgBox2{
  border-radius: 0;
  border-top: 3px solid #7c2af8;
  border-left: 2px solid #e9e9e9;
  border-right: 2px solid #e9e9e9;
  border-bottom: 3px solid #c1c1c1;
} */
.title-line {
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}

.img1 {
  /* margin-top: 50px; */
  /* padding: 0 200px; */
}
</style>